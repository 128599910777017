
import Vue from 'vue';

export default Vue.extend({
    name: 'Home',
    components: {},
    data() {
        return {
            sort: [],
            params:{ server:'1'},
            timeoutId: null,
        }
    },
    mounted() {
        this.resetTimeout();
        // 添加事件监听器，当用户有鼠标移动或键盘输入时重置超时计时器
        document.addEventListener('mousemove', this.resetTimeout);
        document.addEventListener('keydown', this.resetTimeout);
    },
    beforeDestroy() {
        // 在组件销毁前，清理事件监听器和超时计时器
        document.removeEventListener('mousemove', this.resetTimeout);
        document.removeEventListener('keydown', this.resetTimeout);
        clearTimeout(this.timeoutId);
    },
    created() {
        this.postSort();
    },
    methods: {
        resetTimeout() {
            // 重置超时计时器
            clearTimeout(this.timeoutId);
            this.timeoutId = setTimeout(() => {
                this.$router.push('/'); // Replace '/' with the path to your home page
            }, 60000);
        },
        goPage(itme) {
            this.$router.replace({path:itme.path,query: {classify: itme.id}});
        },
        async postSort() {
            try {
                const result = await this.$api.postSort( this.params );
                this.sort = result.data

            } catch (error) {
                // console.log('error', error)
            }
        }
    }
});
